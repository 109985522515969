import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Front Squat 5×2\\@85%1rm`}</p>
    <p>{`Glute Ham Raise 5×4`}</p>
    <p>{`then,`}</p>
    <p>{`21-15-9:`}</p>
    <p>{`Power Cleans (95/65)(RX+ 115/75)`}</p>
    <p>{`Burpees`}</p>
    <p>{`Into,`}</p>
    <p>{`15-12-9:`}</p>
    <p>{`Front Squats`}</p>
    <p>{`Lateral Burpees Over Bar`}</p>
    <p>{`Into,`}</p>
    <p>{`12-9-6:`}</p>
    <p>{`Thrusters`}</p>
    <p>{`Bar Facing Burpees`}</p>
    <p>{`for time.`}</p>
    <p><strong parentName="p"><em parentName="strong">{`*`}{`This Saturday is our free partner Halloween workout so bring a
friend and wear a costume! Best costume wins a prize!`}</em></strong>{` `}<strong parentName="p"><em parentName="strong">{`Classes are
at 9:00 & 10:15.`}</em></strong></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      